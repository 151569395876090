<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Customer Home Products') }}
    </p>
    <section class="content-margin">
      <div class="row">
        <div class="col-sm-3 mb-10">
          <label>{{ $t('Show on') }}</label>
          <vue-select 
            :options="showOn.options" 
            class="input cus-select"
            v-model="showOn.selected"
          ></vue-select>
        </div>
      </div>
      <!-- order part -->
      <div v-if="filteredProduct.length">
        <p class="fs14">{{ $t("Activated Products") }}</p>
        <draggable class="d-flex justify-content-center flex-wrap top-supplier-block" v-model="filteredProduct" @start="drag=true" @end="drag=false" id="home_product_order">
          <div v-for="(card, i) in filteredProduct" :key="i" class="p-relative" v-if="card[showOn.selected.code] && card.tags.includes(41)">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              class="supplier-edit_btn"
              @click="redirectProductManagement(card)"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <SupplierDealCard
              :headerBgColor="card.supplier_image_background_color"
              :logo="card.supplier_image"
              :supplier="card.supplier_name"
              :calls="card.call_minute"
              :sms="card.sms" 
              :network="card.browsing_package"
              :days="card.usage_time_limit"
              :price="card.price"
              :isXbox=false
              :id="card.id"
              :supplier_id="card.supplier_id"
              :name="card.name"
              :type="card.type"
              :show_additional_details="card.show_additional_details"
              :additional_details="card.additional_details"
              :product_router="handleGetProductRouter(card.supplier_type)"
            >
            </SupplierDealCard>
          </div>
        </draggable>
        <div class="d-flex justify-content-center mt-20">
          <v-btn
            color="light"
            class="text-capitalize mx-10"
            @click="cancelOrder()"
          >
            {{ $t("Reset") }}	
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="saveOrder()"
          >
            {{ $t("Save Order") }}	
          </v-btn>
        </div>

        <v-divider></v-divider>
        <p class="fs14">{{ $t("Unshown Products") }}</p>
        <div class="d-flex justify-content-center flex-wrap top-supplier-block">
          <div v-for="(card, i) in filteredProduct" :key="i" class="p-relative" v-if="!card[showOn.selected.code]">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              class="supplier-edit_btn"
              @click="redirectProductManagement(card)"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <SupplierDealCard
              :headerBgColor="card.supplier_image_background_color"
              :logo="card.supplier_image"
              :supplier="card.supplier_name"
              :calls="card.call_minute"
              :sms="card.sms" 
              :network="card.browsing_package"
              :days="card.usage_time_limit"
              :price="card.price"
              :isXbox=false
              :id="card.id"
              :supplier_id="card.supplier_id"
              :name="card.name"
              :type="card.type"
              :show_additional_details="card.show_additional_details"
              :additional_details="card.additional_details"
              :product_router="handleGetProductRouter(card.supplier_type)"
            >
            </SupplierDealCard>
          </div>
        </div>
      </div>
    </section>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>    
</template>

<script>
import { mapState, mapActions } from "vuex";
import SupplierCard from '@/components/endUser/SupplierCard';
import SupplierDealCard from '@/components/endUser/home/SupplierDealCard';


export default {
  name: 'CPCustomerHomeProducts',
  components: {
    SupplierCard,
    SupplierDealCard
  },
  data() {
    return {
      supplierCardsData: [],
      supplierCategory: {
        options: [],
        selected: ''
      },
      showOn: {
        options: [
          {
            code: 'show_destop',
            label: this.$t('Desktop')
          },
          {
            code: 'show_tablet',
            label: this.$t('Tablet')
          },
          {
            code: 'show_mobile',
            label: this.$t('Mobile')
          },
        ],
        selected: {
          code: 'show_destop',
          label: this.$t('Desktop')
        }
      },
      tempFilteredProduct: [],
      filteredProduct: []
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.product.product_module_loading,
      products: state => state.product.products,
    }),
    storageSupplierCategoryId() {
      return localStorage.getItem("cpSupplierCategory");
    },
  },
  async created () {
    await this.getProducts({'params':
      {
        tag: 41,
        supplier_type: [1, 4, 6]
      }
    });

    if (this.products.length) {
      this.filteredProduct = this.products.filter(el => {
        return el != undefined;
      }).sort((a,b) => a.customer_home_order - b.customer_home_order);
    }
  },
  methods: {
    ...mapActions("product", {
      getProducts: 'getProducts',
      updateHomeOrder: 'updateHomeOrder',
    }),
    handleGetProductRouter(type) {
      switch(type) {
        case 6:
          return "EndUserGameProduct"
        case 4:
          return "EndUserGiftCardProduct"
        case 1:
          return "EndUserCommunicationProduct"
      }
    },
    cancelOrder() {
      this.supplierCardsData = this.products.filter(el => {
        return el != undefined;
      }).sort((a,b) => a.customer_home_order - b.customer_home_order);
    },
    saveOrder() {
      let params = [];
      this.filteredProduct.forEach((el, index) => {
        params.push({
          id: el.id,
          order: index + 1
        })
      });
      this.updateHomeOrder({params});
    },
    redirectProductManagement(card) {
      const type = this.handleGetSupplierTypeString(card.type);
      localStorage.setItem("cpSupplierCategory", type);
      localStorage.setItem("cpSuppliersId", card.supplier_id);
      localStorage.setItem("cpProductId", card.id);
      this.$router.push({ name: 'ProductManagement' });

    },
    handleGetSupplierTypeString(type) {
      switch(type) {
        case 6:
          return "games"
        case 4:
          return "gift_cards"
        case 1:
          return "prepaid"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .supplier-edit_btn {
    position: absolute;
    right: 0;
    top: -3px;
    z-index: 10;
  }
</style>